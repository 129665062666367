<template>
<div class="card bg-soft-info mb-3 check-info-card text-center">
    <div class="card-body">
        <h4 class="card-title text-primary">Time spend</h4>
        <span class="fs-1">
            <span v-if="days > 0">{{ days }}:</span>
            <span v-if="hours > 0">{{ hours }}:</span>
            <span>{{ minutes }}:</span>
            <span>{{ seconds }}</span>
        </span>
    </div>
</div>
</template>

<script>
export default {
    props: {
        start_datetime: {
            type: Date,
            required: true
        }
    },

    data() {
        return {
            milliseconds_total: null
        }
    },
    
    computed: {
        seconds() {
            let value = Math.floor((this.milliseconds_total / 1000) % 60)
            if (value < 10) {
                return '0' + value
            }
            return value
        },
        minutes() {
            let value = Math.floor((this.milliseconds_total / 1000 / 60) % 60)
            if (value < 10) {
                return '0' + value
            }
            return value
        },
        hours() {
            let value = Math.floor((this.milliseconds_total / (1000 * 60 * 60)) % 24);
            if (value < 10) {
                return '0' + value
            }
            return value
        },
        days() {
            return Math.floor(this.milliseconds_total / (1000 * 60 * 60 * 24));
        }
    },

    methods: {
        update_clock() {
            this.milliseconds_total = new Date().getTime() - this.start_datetime

            setTimeout(this.update_clock, 1000)
        }
    },

    mounted() {
        this.update_clock()
    }

}
</script>

<style lang="scss" scoped>

</style>